import * as React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { Style } from '../../../styles'

const Container = styled.div`
  flex-direction: column;
  padding: 0 4px 0 0;
  margin: 8px 4px 8px 8px;
  max-height: 390px;
  max-width: 360px;
	min-width: 360px;
  overflow: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    background: white;
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 4px;

    &:hover {
      background: #7d7d7d;
    }
  }

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		max-width: 310px;
		min-width: 310px;
	}
`

const EmptyContainer = styled.div`
	font-size: 14px;
`

const Section = styled.div``

const SectionHeader = styled.div`
	background-color: hsl(0,0%,95%);
	font-weight: 700;
	margin-bottom: 3px;
	padding: 8px;
	line-height: 1;
	position: sticky;
	z-index: 1;
	top: 0;
	border-radius: ${Style.variables.baseBorderRadius};
`

const SectionItem = styled.div`
	display: flex;
	flex-direction: row;
	border-radius: 5px;
	border: 1px solid transparent;
	padding: 8px;
	margin-bottom: 3px;
	transition: none;
	min-height: 31px;
	cursor: pointer;
	display: flex;
	gap: 12px;

  &:hover {
		background-color: hsl(0,0%,95%);
		border-color: ${Style.color.border};
		border-style: solid;
	}
`

const SectionItemPart = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;

	span {
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&:first-child {
		border-right: 1px solid ${Style.color.border};
	}
`

interface IProps {
	variables: any[]
	onClick: (value: string) => void
}

const VariableList = (props: IProps) => {
	const { t } = useTranslation()
	const { variables, onClick } = props

	return (
		<Container>
			{variables.length === 0 && <EmptyContainer>
				{t('VariableList::No variables found')}
			</EmptyContainer>}
			{variables.length > 0 && variables.map((variable, index) => {
				const { options } = variable
				return (
					<Section key={index}>
						<SectionHeader>
							{variable.label}
						</SectionHeader>

						{options.map((option, index) => {
							return (
								<SectionItem key={option.name} onClick={() => onClick(option.value)}>
									<SectionItemPart title={option.label}>
										{option.label}
									</SectionItemPart>
									<SectionItemPart title={option.placeholder}>
										<span>
											{option.placeholder}
										</span>
									</SectionItemPart>
								</SectionItem>
							)
						})}
					</Section>
				)
			})}
		</Container>
	)
}

export default VariableList